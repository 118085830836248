<script lang="ts" setup>
import * as Sentry from '@sentry/vue'
const { showError } = useToast()

interface Error {
  statusCode?: number;
}

onErrorCaptured((error: Error) => {
  if (!import.meta.env.DEV) {
    Sentry.captureException(error)
  } else {
    console.error(error)
  }
  if (error.statusCode === 404) {
    return true
  }
  showError(`Error ${error.statusCode}`)
  clearError()
  return false
})
</script>

<template>
  <div class="position-relative">
    <slot />
  </div>
</template>
