<script setup lang="ts">
const props = defineProps<{
  label?: string,
  icon?: string,
  iconColor?: string,
  ariaLabel?: string,
  rounded?: boolean
  size?: 'small' | 'medium' | 'large',
  version?: 'default' | 'primary' | 'secondary' | 'save' | 'info' | 'danger',
  outline?: boolean
  block?: boolean
  link?: boolean
  tight?: boolean
  backgroundColor?: string
  inline?: boolean,
  hideLabel?: boolean,
  disabled?: boolean,
  type?: 'submit' | 'reset' | 'button'
}>()
</script>

<template>
  <button
    class="mp-button"
    :class="`
      ${props.size ? props.size : 'medium' }
      ${props.rounded ? 'rounded' : '' }
      ${props.outline ? 'outline' : '' }
      ${props.block ? 'block' : '' }
      ${props.link ? 'link' : '' }
      ${props.tight ? 'tight' : '' }
      ${props.version ?? 'default' }
      ${props.icon && !props.label ? 'icon-only' : '' }
      ${props.inline ? 'inline' : '' }
      ${props.backgroundColor === 'transparent' ? 'no-border' : '' }
      ${props.disabled ? 'disabled' : '' }
    `"
    :aria-label="props.ariaLabel ?? props.label"
    :style="`background-color: ${props.backgroundColor}`"
    :type="props.type"
    :disbaled="props.disabled"
  >
    <i v-if="props.icon" :class="`${props.icon} icon`" />
    <label v-if="!props.hideLabel && props.label">
      {{ props.label }}
    </label>
    <slot />
  </button>
</template>

<style lang="scss" scoped>
@import '@/assets/css/typography.scss';
.mp-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: 0 none;
  border: 0 none;
  cursor: pointer;
  border-radius: 0.5rem;
  margin: 0;
  @include mp-font-body-regular;
  padding: 0.5rem 1rem;
  font: var(--body-regular);
  i {
    cursor: pointer;
    font-size: 1rem;
  }
  label {
    cursor: pointer;
  }
  .icon + label {
    margin-left: 0.5rem;
  }
  &.disabled {
    opacity: 0.5 !important;
  }
  &.rounded {
    border-radius: 2em;
  }
  &.small {
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
  }
  &.medium {
    font-size: 1rem;
    padding: 0.5rem 1.25rem;
  }
  &.large {
    padding: 0.75rem 2rem;
    font-size: 1.125rem;
    font-weight: 500;
  }
  &.tight {
    padding: 0.25rem 0.55rem;
  }
  &.default {
    background-color: var(--color-slate-900);
    color: var(--color-slate-50);
  }
  &.secondary {
    background-color: var(--color-slate-50);
    color: var(--color-slate-600);
    border: 1px solid var(--color-slate-100);
  }
  &.primary {
    background-color: var(--color-rose-500);
    color: var(--color-rose-50);
  }
  &.save {
    background-color: var(--color-green-500);
    color: var(--color-green-50);
  }
  &.info {
    background-color: var(--color-indigo-500);
    color: var(--color-indigo-50);
  }
  &.danger {
    background-color: var(--color-rose-500);
    color: var(--color-rose-50);
  }
}

.link {
  &.default {
    background-color: transparent;
    color: var(--color-slate-600);
    &:hover {
      color: var(--color-slate-800);
    }
  }
  &.secondary {
    background-color: transparent;
    color: var(--color-slate-400);
  }
  &.primary {
    background-color: transparent;
    color: var(--color-rose-400);
  }
  &.save {
    background-color: transparent;
    color: var(--color-green-400);
  }
  &.info {
    background-color: transparent;
    color: var(--color-indigo-400);
  }
  &.danger {
    background-color: transparent;
    color: var(--color-rose-500);
  }
}

.inline {
  padding-left: 0;
  padding-right: 0;
}

.outline {
  background-color: transparent;
  &.default {
    background-color: transparent;
    color: var(--color-slate-900);
    border: 1px solid var(--color-slate-900);
  }
  &.secondary {
    background-color: transparent;
    color: var(--color-slate-400);
    border: 1px solid var(--color-slate-400);
  }
  &.primary {
    background-color: transparent;
    color: var(--color-rose-400);
    border: 1px solid var(--color-rose-400);
  }
  &.save {
    background-color: transparent;
    color: var(--color-green-400);
    border: 1px solid var(--color-green-400);
  }
  &.info {
    background-color: transparent;
    color: var(--color-indigo-400);
    border: 1px solid var(--color-indigo-400);
  }
  &.danger {
    background-color: transparent;
    color: var(--color-rose-500);
    border: 1px solid var(--color-rose-500);
  }
}

.icon-only,
.icon-only.small,
.icon-only.large {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  position: relative;
  padding: 0;
  i {
    font-size: 1.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.icon-only.small {
  width: 2rem;
  height: 2rem;
  i {
    font-size: 1.25rem;
  }
}


.icon-only.large {
  width: 3rem;
  height: 3rem;
  i {
    font-size: 2.75rem;
  }
}

</style>

<!-- <style lang="scss" scoped>
.mp-button {
  outline: 0 none;
  border: 0 none;
  cursor: pointer;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-slate-900);
  color: var(--color-slate-50);
  i {
    font-size: 1.2em;
    + label {
      margin-left: 0.25rem;
    }
  }
  &.block {
    display: block;
    width: 100%;
  }
  &.small {
    padding: 0.25rem 0.35rem;
    font-size: 1rem;
  }
  &.medium {
    padding: 0.75rem;
    font-size: 1.2rem;
  }
  &.large {
    padding: 0.75rem;
  }
  &:has(label).small {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &:has(label).medium {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &:has(label).large {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &.rounded {
    border-radius: 2em;
  }
  &.outline {
    border: 1px solid var(--color-slate-200);
    background-color: transparent;
  }
}
</style> -->
