<script setup lang="ts">
const dialogRef = ref<HTMLDialogElement | null>(null)

const showDialog = () => {
  dialogRef.value?.showModal()
}
const closeDialog = () => {
  dialogRef.value?.close()
}

defineExpose({ showDialog, closeDialog })

// onMounted(() => {
//   if (props.blockScroll) {
//     document.body.style.overflow = 'hidden'
//   }
// })

// onUnmounted(() => {
//   if (props.blockScroll) {
//     document.body.style.overflow = ''
//   }
// })
//
const closeFromEvent = (e: Event): void => {
  if (e.currentTarget === e.target) {
    closeDialog()
  }
}
</script>

<template>
  <dialog ref="dialogRef" @click="closeFromEvent">
    <slot/>
  </dialog>
</template>

<style scoped>
dialog {
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  border: 0;
  box-shadow: var(--shadow-lg);
  background-color: white;
}
</style>
