import type { PropType, InputHTMLAttributes } from 'vue'

export const formProps = {
  placeholder: {
    type: String,
    default: null
  },
  type: {
    type: String as InputHTMLAttributes,
    default: 'text'
  },
  options: {
    type: Array as PropType<{
      label: string,
      value: string | number
    }[]>,
    default: null
  },
  label: {
    type: String,
    default: null,
    required: true
  },
  helpText: {
    type: String,
    default: null,
    required: false
  },
  hideLabel: {
    type: Boolean,
    default: false
  },
  floatLabel: {
    type: Boolean,
    default: false
  }
}
