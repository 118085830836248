import * as validators from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'

export const useValidators = () => {
  const { t } = useI18n()
  const withI18nMessage = validators.createI18nMessage({ t })
  return {
    required: withI18nMessage(validators.required),
    email: withI18nMessage(validators.email),
    minLength: withI18nMessage(validators.minLength, { withArguments: true }),
    maxLength: withI18nMessage(validators.maxLength, { withArguments: true }),
    sameAs: withI18nMessage(validators.sameAs, { withArguments: true }),
    numeric: withI18nMessage(validators.numeric),
    url: withI18nMessage(validators.url)
  }
}
