import revive_payload_client_QVYZevFQWB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@22.9.0_eslint@9.14.0_rollup@4.27.0_sass@1.81.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BhiX4d5sJ5 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@22.9.0_eslint@9.14.0_rollup@4.27.0_sass@1.81.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CO4TwP3UVw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@22.9.0_eslint@9.14.0_rollup@4.27.0_sass@1.81.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_tybaPFvkZx from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.2/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_PFVYd0nZoh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.27.0_vite@5.4.11_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_U6VxtOQKMA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@22.9.0_eslint@9.14.0_rollup@4.27.0_sass@1.81.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VpzvpKbo5u from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@22.9.0_eslint@9.14.0_rollup@4.27.0_sass@1.81.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RUedtTUjSO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@22.9.0_eslint@9.14.0_rollup@4.27.0_sass@1.81.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_fIDUuDJL24 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@22.9.0_eslint@9.14.0_rollup@4.27.0_sass@1.81.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_vCYcEPHnCJ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_rollup@4.27.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_Nnb2vC9xXC from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_rollup@4.27.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_jawwauNSFn from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.27.0_vite@5.4.11_vue@3.5.12/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_XCfNT7chF6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.27.0_vite@5.4.11_vue@3.5.12/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_R7Y0sG8u6Z from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.27.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_7zaCVLnlqW from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.27.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import umami_iN1DWXMIUx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-umami@2.6.7/node_modules/nuxt-umami/plugins/umami.ts";
import scrollTo_IIJedyuVwF from "/opt/buildhome/repo/plugins/scrollTo.ts";
import stripe_6FZUdDHpIZ from "/opt/buildhome/repo/plugins/stripe.ts";
import sentry_3AyO8nEfhE from "/opt/buildhome/repo/plugins/sentry.ts";
export default [
  revive_payload_client_QVYZevFQWB,
  unhead_BhiX4d5sJ5,
  router_CO4TwP3UVw,
  supabase_client_tybaPFvkZx,
  _0_siteConfig_PFVYd0nZoh,
  navigation_repaint_client_U6VxtOQKMA,
  check_outdated_build_client_VpzvpKbo5u,
  chunk_reload_client_RUedtTUjSO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fIDUuDJL24,
  titles_vCYcEPHnCJ,
  defaultsWaitI18n_Nnb2vC9xXC,
  siteConfig_jawwauNSFn,
  inferSeoMetaPlugin_XCfNT7chF6,
  switch_locale_path_ssr_R7Y0sG8u6Z,
  i18n_7zaCVLnlqW,
  umami_iN1DWXMIUx,
  scrollTo_IIJedyuVwF,
  stripe_6FZUdDHpIZ,
  sentry_3AyO8nEfhE
]