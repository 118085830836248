<script lang="ts" setup>
const dialogTarget = ref<InstanceType<typeof MPDialog>>(null)
const { setDialogRef, closeDialog } = useDialog()
const { fetchCurrentUser, currentUser } = useAuth()
const route = useRoute()

const onSuccess = async () => {
  await fetchCurrentUser()
  if (currentUser.value) closeDialog()
}

onMounted(() => {
  setDialogRef(dialogTarget)
})
</script>
<template>
  <div>
    <ErrorBoundry>
      <div class="page-layout">
        <div class="header-desktop">
          <MPHeader />
        </div>
        <div class="app-layout">
          <NuxtLayout>
            <NuxtPage />
          </NuxtLayout>
        </div>
        <div class="footer-desktop">
          <MPFooter />
        </div>
      </div>
      <MPDialog ref="dialogTarget">
        <MPAuth
          v-if="!route.path.includes('auth')"
          :title="$t('auth.title')"
          :summary="$t('auth.summary')"
          @on-auth-success="onSuccess"
          @on-signup-success="onSuccess"
        />
        <!-- // esle subscribe -->
      </MPDialog>
      <MPToast />
    </ErrorBoundry>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/breakpoints.pcss";

.page-layout {
  display: block;
  @include screen lg  {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
}
.app-layout {
  align-content: start;
  min-height: calc(100vh - 8rem);
}
</style>
