<script setup lang="ts">
const results = ref([])
const searchTerm = ref('')
const isLoading = ref(false)
const showResults = ref(false)
const localePath = useLocalePath()
const supabase = useSupabaseClient()

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Search'
  }
})

const clickOutside = (event: MouseEvent) => {
  const searchContainer = document.querySelector('.search-container')
  if (searchContainer && !searchContainer.contains(event.target as Node)) {
    showResults.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', clickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', clickOutside)
})

watch(searchTerm, useDebounceFn(() => {
  if (searchTerm.value && searchTerm.value !== '') {
    isLoading.value = true
    ftsSearch()
  }
}, 250))

const ftsSearch = async () => {
  const searchParams = {
    results_per_type: 40
  }

  if (searchTerm.value && searchTerm.value !== '') {
    searchParams.search_query = searchTerm.value
  }

  const { data } = await supabase.rpc('search_all', searchParams)

  results.value = data
  showResults.value = true
  isLoading.value = false
}

// const router = useRouter()
const handleResultClick = (route: string) => {
  showResults.value = false
  window.location.href = route
  // router.push({ path: route })
}

</script>

<template>
  <div class="search-container">
    <div class="search-input-wrapper" @click="showResults = true">
      <input
        v-model="searchTerm"
        class="search-input"
        :placeholder="props.placeholder"
      >
    </div>
    <div v-if="!isLoading && searchTerm && showResults && results && results.length > 0" class="search-results">
      <h4>Top Results</h4>
      <small>{{ results.length }} results</small>
      <div v-for="result in results.filter(result => result.type === 'map')" :key="result.id" class="search-result" @click="handleResultClick(localePath(`/maps/${result.id}`))">
        <MPImg :src="result.cover_image_url" /><span>{{ result.title }}<br><small>{{ result.type }}<span v-if="result.country_names[0]"> - {{ result.country_names[0] }}</span></small></span>
      </div>
      <div v-for="result in results.filter(result => result.type === 'profile')" :key="result.id" class="search-result" @click="handleResultClick(localePath(`/@${result.mappr_handle}`))">
        <MPImg :src="result.profile_image_url" /><span>{{ result.title }}<br><small>Creator</small></span>
      </div>
      <div v-for="result in results.filter(result => result.type === 'spot')" :key="result.id" class="search-result" @click="handleResultClick(localePath(`/maps/${result.foreign_id}/spots/${result.id}`))">
        <MPImg :src="result.cover_image_url" /><span>{{ result.title }}<br><small>{{ result.type }} <span v-if="result.city_names[0]"> - {{ result.city_names[0] }}</span></small></span>
      </div>
    </div>

    <div v-if="!isLoading && searchTerm && showResults && results && results.length === 0" class="search-results">
      <p>{{ $t('search.noResults') }}</p>
    </div>
    <div v-if="isLoading" class="search-results">
      <p>Loading...</p>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/typography.pcss";
@import "@/assets/css/breakpoints.pcss";
@import "@/assets/css/shadow.pcss";

.search-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.search-results {
  position: absolute;
  top: 100%;
  right: 0;
  width: 25rem;
  background-color: white;
  z-index: 100;
  max-height: 30rem;
  overflow-y: scroll;
  margin-top: 0.5rem;
  border-radius: 1rem;
  scrollbar-width: none;
  border: 1px solid var(--color-slate-50);
  h4 {
    font-size: 1.2rem;
    color: var(--color-slate-600);
    margin: 0.5rem 1rem 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.5;
    border-bottom: 1px solid var(--color-slate-50);
  }
  p {
    margin: 1rem;
    text-align: center;
    color: var(--color-slate-400);
  }
  h4 + small {
    color: var(--color-slate-400);
    font-size: 0.8rem;
    margin: 0 1rem;
    line-height: 1.5;
    text-transform: capitalize;
    position: absolute;
    top: 1.15rem;
    right: 0;
  }
}

.search-result {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: var(--color-slate-700);
  cursor: pointer;
  img {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 0.5rem;
  }
  small {
    color: var(--color-slate-400);
    font-size: 0.8rem;
    text-transform: capitalize;
  }
  &:hover {
    background-color: var(--color-slate-50);
  }
}

.search-input-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  &:before {
    font-family: "remixicon";
    content: "\F0CD";
    position: absolute;
    color: var(--color-slate-400);
    left: 0.5rem;
    align-items: center;
  }
  input {
    padding: 0.5rem 1rem 0.5rem 1.75rem;
    border-radius: 1rem;
    font-size: 0.9rem;
    width: 100%;
    max-width: 28rem;
    border: 1px solid var(--color-slate-100);
    background: var(--color-slate-50);
    color: var(--color-slate-500);
    outline: none;
  }
  input:focus {
    outline: none;
    border-color: var(--color-slate-200);
  }
}
</style>
