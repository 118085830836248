import { default as accountFCGaMrzILUMeta } from "/opt/buildhome/repo/pages/account.vue?macro=true";
import { default as atlasvqPRlFer6dMeta } from "/opt/buildhome/repo/pages/atlas.vue?macro=true";
import { default as authdjl0VO0QO1Meta } from "/opt/buildhome/repo/pages/auth.vue?macro=true";
import { default as _91handle_93YCf40QU5gmMeta } from "/opt/buildhome/repo/pages/creators/[handle].vue?macro=true";
import { default as apply2zzEFQOuBaMeta } from "/opt/buildhome/repo/pages/creators/apply.vue?macro=true";
import { default as index1qSNv4QjJhMeta } from "/opt/buildhome/repo/pages/creators/index.vue?macro=true";
import { default as _91id_93lv1ypkESBEMeta } from "/opt/buildhome/repo/pages/destinations/[id].vue?macro=true";
import { default as indexeI7uwUE8N4Meta } from "/opt/buildhome/repo/pages/destinations/index.vue?macro=true";
import { default as explorerYI361piByMeta } from "/opt/buildhome/repo/pages/explore.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91spotId_93iXCjkO1k7rMeta } from "/opt/buildhome/repo/pages/maps/[mapId]/spots/[spotId].vue?macro=true";
import { default as _91mapId_93bstacBQhfKMeta } from "/opt/buildhome/repo/pages/maps/[mapId].vue?macro=true";
import { default as indexoj7FnRPwBsMeta } from "/opt/buildhome/repo/pages/maps/index.vue?macro=true";
import { default as indexETnSRehETwMeta } from "/opt/buildhome/repo/pages/studio/admin/articles/index.vue?macro=true";
import { default as indexRgu7a7sc3AMeta } from "/opt/buildhome/repo/pages/studio/admin/categories/index.vue?macro=true";
import { default as _91id_93K0CBk82Ne5Meta } from "/opt/buildhome/repo/pages/studio/admin/creators/[id].vue?macro=true";
import { default as indexe42xLr0njwMeta } from "/opt/buildhome/repo/pages/studio/admin/creators/index.vue?macro=true";
import { default as indexs8dBGd3IlbMeta } from "/opt/buildhome/repo/pages/studio/admin/destinations/index.vue?macro=true";
import { default as indexVSWmJaB5TRMeta } from "/opt/buildhome/repo/pages/studio/admin/index.vue?macro=true";
import { default as bankhOaiz089unMeta } from "/opt/buildhome/repo/pages/studio/bank.vue?macro=true";
import { default as index7GEH0PdETpMeta } from "/opt/buildhome/repo/pages/studio/creator-faq/index.vue?macro=true";
import { default as indexH9zLNkmCOnMeta } from "/opt/buildhome/repo/pages/studio/maps/[mapId]/index.vue?macro=true";
import { default as _91spotId_93Ic7fbvoROhMeta } from "/opt/buildhome/repo/pages/studio/maps/[mapId]/spots/[spotId].vue?macro=true";
import { default as _91mapId_93QJI1KOMxCKMeta } from "/opt/buildhome/repo/pages/studio/maps/[mapId].vue?macro=true";
import { default as google_45saved_45listso082YBM1V7Meta } from "/opt/buildhome/repo/pages/studio/maps/import/google-saved-lists.vue?macro=true";
import { default as indexYxhp6wn5QKMeta } from "/opt/buildhome/repo/pages/studio/maps/index.vue?macro=true";
import { default as mapsv0kMzc4DMAMeta } from "/opt/buildhome/repo/pages/studio/maps.vue?macro=true";
import { default as payouts5ySYRrUDqPMeta } from "/opt/buildhome/repo/pages/studio/payouts.vue?macro=true";
import { default as indexdLuFHqS45fMeta } from "/opt/buildhome/repo/pages/studio/posts/index.vue?macro=true";
import { default as _91postId_93kCljHhfiwHMeta } from "/opt/buildhome/repo/pages/studio/posts/postId/[postId].vue?macro=true";
import { default as profile3s0XbPqYNpMeta } from "/opt/buildhome/repo/pages/studio/profile.vue?macro=true";
import { default as reset_45passwordsRP8Y0dicsMeta } from "/opt/buildhome/repo/pages/studio/reset-password.vue?macro=true";
import { default as studioBTpCLYcjldMeta } from "/opt/buildhome/repo/pages/studio.vue?macro=true";
import { default as _91handle_93vPEAvpB6rgMeta } from "~/pages/creators/[handle].vue?macro=true";
import { default as component_45stubLXax1VDlHdMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@22.9.0_eslint@9.14.0_rollup@4.27.0_sass@1.81.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubLXax1VDlHd } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@22.9.0_eslint@9.14.0_rollup@4.27.0_sass@1.81.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account___en",
    path: "/account",
    meta: accountFCGaMrzILUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account.vue")
  },
  {
    name: "atlas___en",
    path: "/atlas",
    meta: atlasvqPRlFer6dMeta || {},
    component: () => import("/opt/buildhome/repo/pages/atlas.vue")
  },
  {
    name: "auth___en",
    path: "/auth",
    component: () => import("/opt/buildhome/repo/pages/auth.vue")
  },
  {
    name: "creators-handle___en",
    path: "/creators/:handle()",
    meta: _91handle_93YCf40QU5gmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/creators/[handle].vue")
  },
  {
    name: "creators-apply___en",
    path: "/creators/apply",
    component: () => import("/opt/buildhome/repo/pages/creators/apply.vue")
  },
  {
    name: "creators___en",
    path: "/creators",
    component: () => import("/opt/buildhome/repo/pages/creators/index.vue")
  },
  {
    name: "destinations-id___en",
    path: "/destinations/:id()",
    component: () => import("/opt/buildhome/repo/pages/destinations/[id].vue")
  },
  {
    name: "destinations___en",
    path: "/destinations",
    component: () => import("/opt/buildhome/repo/pages/destinations/index.vue")
  },
  {
    name: "explore___en",
    path: "/explore",
    component: () => import("/opt/buildhome/repo/pages/explore.vue")
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "maps-mapId___en",
    path: "/maps/:mapId()",
    meta: _91mapId_93bstacBQhfKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/maps/[mapId].vue"),
    children: [
  {
    name: "maps-mapId-spots-spotId___en",
    path: "spots/:spotId()",
    component: () => import("/opt/buildhome/repo/pages/maps/[mapId]/spots/[spotId].vue")
  }
]
  },
  {
    name: "maps___en",
    path: "/maps",
    component: () => import("/opt/buildhome/repo/pages/maps/index.vue")
  },
  {
    name: "studio___en",
    path: "/studio",
    meta: studioBTpCLYcjldMeta || {},
    component: () => import("/opt/buildhome/repo/pages/studio.vue"),
    children: [
  {
    name: "studio-admin-articles___en",
    path: "admin/articles",
    component: () => import("/opt/buildhome/repo/pages/studio/admin/articles/index.vue")
  },
  {
    name: "studio-admin-categories___en",
    path: "admin/categories",
    component: () => import("/opt/buildhome/repo/pages/studio/admin/categories/index.vue")
  },
  {
    name: "studio-admin-creators-id___en",
    path: "admin/creators/:id()",
    component: () => import("/opt/buildhome/repo/pages/studio/admin/creators/[id].vue")
  },
  {
    name: "studio-admin-creators___en",
    path: "admin/creators",
    component: () => import("/opt/buildhome/repo/pages/studio/admin/creators/index.vue")
  },
  {
    name: "studio-admin-destinations___en",
    path: "admin/destinations",
    component: () => import("/opt/buildhome/repo/pages/studio/admin/destinations/index.vue")
  },
  {
    name: "studio-admin___en",
    path: "admin",
    component: () => import("/opt/buildhome/repo/pages/studio/admin/index.vue")
  },
  {
    name: "studio-bank___en",
    path: "bank",
    component: () => import("/opt/buildhome/repo/pages/studio/bank.vue")
  },
  {
    name: "studio-creator-faq___en",
    path: "creator-faq",
    component: () => import("/opt/buildhome/repo/pages/studio/creator-faq/index.vue")
  },
  {
    name: mapsv0kMzc4DMAMeta?.name,
    path: "maps",
    component: () => import("/opt/buildhome/repo/pages/studio/maps.vue"),
    children: [
  {
    name: _91mapId_93QJI1KOMxCKMeta?.name,
    path: ":mapId()",
    component: () => import("/opt/buildhome/repo/pages/studio/maps/[mapId].vue"),
    children: [
  {
    name: "studio-maps-mapId___en",
    path: "",
    meta: indexH9zLNkmCOnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/studio/maps/[mapId]/index.vue")
  },
  {
    name: "studio-maps-mapId-spots-spotId___en",
    path: "spots/:spotId()",
    component: () => import("/opt/buildhome/repo/pages/studio/maps/[mapId]/spots/[spotId].vue")
  }
]
  },
  {
    name: "studio-maps-import-google-saved-lists___en",
    path: "import/google-saved-lists",
    component: () => import("/opt/buildhome/repo/pages/studio/maps/import/google-saved-lists.vue")
  },
  {
    name: "studio-maps___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/studio/maps/index.vue")
  }
]
  },
  {
    name: "studio-payouts___en",
    path: "payouts",
    component: () => import("/opt/buildhome/repo/pages/studio/payouts.vue")
  },
  {
    name: "studio-posts___en",
    path: "posts",
    component: () => import("/opt/buildhome/repo/pages/studio/posts/index.vue")
  },
  {
    name: "studio-posts-postId-postId___en",
    path: "posts/postId/:postId()",
    component: () => import("/opt/buildhome/repo/pages/studio/posts/postId/[postId].vue")
  },
  {
    name: "studio-profile___en",
    path: "profile",
    component: () => import("/opt/buildhome/repo/pages/studio/profile.vue")
  },
  {
    name: "studio-reset-password___en",
    path: "reset-password",
    component: () => import("/opt/buildhome/repo/pages/studio/reset-password.vue")
  }
]
  },
  {
    name: "profile___en",
    path: "/@:handle",
    meta: _91handle_93vPEAvpB6rgMeta || {},
    component: () => import("~/pages/creators/[handle].vue")
  },
  {
    name: component_45stubLXax1VDlHdMeta?.name,
    path: "/search",
    component: component_45stubLXax1VDlHd
  },
  {
    name: component_45stubLXax1VDlHdMeta?.name,
    path: "/app/:pathMatch(.*)",
    component: component_45stubLXax1VDlHd
  },
  {
    name: component_45stubLXax1VDlHdMeta?.name,
    path: "/app/account",
    component: component_45stubLXax1VDlHd
  },
  {
    name: component_45stubLXax1VDlHdMeta?.name,
    path: "/app/bookmarks",
    component: component_45stubLXax1VDlHd
  },
  {
    name: component_45stubLXax1VDlHdMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubLXax1VDlHd
  },
  {
    name: component_45stubLXax1VDlHdMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubLXax1VDlHd
  },
  {
    name: component_45stubLXax1VDlHdMeta?.name,
    path: "/en-EN-sitemap.xml",
    component: component_45stubLXax1VDlHd
  }
]