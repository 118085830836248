<script setup lang="ts">
import { useAttrs } from 'vue'
import { Field } from '@ark-ui/vue'
import { formProps } from '../formProps'

const attrs = useAttrs()
const model = defineModel<string>()
const props = defineProps({
  rounded: {
    type: Boolean,
    default: false
  },
  ...formProps
})

const inputId = attrs.name?.toLowerCase().replace(/[^a-z0-9]+/g, '-')

const updateModel = (event: Event) => {
  const target = event.target as HTMLInputElement
  model.value = target.value
}
</script>

<template>
  <ClientOnly>
    <Field.Root :id="inputId">
      <Field.Label :class="hideLabel ? 'sr-only' : ''">
        {{ props.label }}
      </Field.Label>
      <Field.Input
        v-bind="$attrs"
        :id="inputId"
        :value="model"
        :placeholder="props.placeholder"
        :type="props.type"
        :disabled="props.disabled || props.readonly"
        :class="props.rounded ? 'rounded' : ''"
        @input="updateModel"
      />
      <Field.HelperText>{{ props.helpText }}</Field.HelperText>
      <Field.ErrorText>Error Info</Field.ErrorText>
    </Field.Root>
  </ClientOnly>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/typography.pcss";
@import "@/assets/css/input.pcss";

[data-part="root"] {
  width: fit-container;
  display: grid;
  text-align: left;
}

[data-part="input"] {
  @mixin mp-input;
  font-size: 1rem;
}

[data-part="input"].rounded {
  @mixin mp-input;
  font-size: 1rem;
  border-radius: 2rem;
}

[data-part="input"]:disabled {
  opacity: 0.6
}

[data-part="label"] {
  @mixin mp-input-label;
  cursor: not-allowed;
}

[data-part="helper-text"] {
  @mixin mp-helper-text;
}

[data-part="error-text"] {
  @mixin mp-error-text;
}
</style>
