<script lang="ts" setup>
const { formatedImageUrl } = useStorage()
const props = defineProps({
  src: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    default: 800
  },
  height: {
    type: Number,
    default: 600
  },
  quality: {
    type: Number,
    default: 75
  },
  resize: {
    type: String,
    default: 'cover'
  },
  overlay: {
    type: Boolean,
    default: false
  }
})

const imageSrc = computed(() => formatedImageUrl(props.src, props.width, props.height, props.quality, props.resize))

</script>

<template>
  <img
    loading="lazy"
    :src="props.src ? imageSrc : '/media/images/placeholder.jpg'"
    class="image"
  >
</template>

<style lang="scss" scoped>
.image {
  max-width: 100%;
}
</style>
