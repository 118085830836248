<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { Menu } from '@ark-ui/vue/menu'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})

const isOpen = ref(false)
const target = ref(null)
onClickOutside(target, () => isOpen.value = false)
</script>

<template>
  <Menu.Root :open="isOpen">
    <Menu.Trigger @click="isOpen = !isOpen">
      <slot/>
    </Menu.Trigger>
    <Menu.Positioner>
      <Menu.Content v-if="isOpen"  ref="target">
        <Menu.Item v-for="item in props.items" :key="item.label" :value="item.label" as-child @click="isOpen = false">
          <NuxtLink v-if="item.link" :to="item.link">
            <i v-if="item.icon" :class="item.icon" />
            {{ item.label }}
          </NuxtLink>
          <div v-else-if="item.action" @click="item.action">
            <i v-if="item.icon" :class="item.icon" />
            {{ item.label }}
          </div>
        </Menu.Item>
      </Menu.Content>
    </Menu.Positioner>
  </Menu.Root>
</template>

<style scoped>
[data-part="root"] {
  position: relative;
}

[data-part="trigger"] {
  all: unset;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  display: flex;
}

/* [data-part="content"] {
  all: unset;
  position: absolute;
  top: 100%;
  left: 0;
  width: fit-content;
  background-color: var(--color-slate-50);
  border-radius: 0.5rem;
  padding: 0.5rem;
  z-index: 30;
} */

[data-part="content"] {
  border-radius: 0.5rem;
  width: fit-content;
  min-width: 10rem;
  z-index: 9999;
  max-height: 20rem;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-slate-100);
  box-shadow: var(--shadow-sm);
  padding: 1rem 0 1rem 1rem;
  outline: none;
  background-color: var(--color-white)
}

[data-part="content"]:focus {
  outline: none;
}

[data-part="item"] {
  padding: 0.75rem 2rem 0.75rem 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.5rem;
  font-weight: 500;
  color: var(--color-slate-700);
  i {
    transition: color 0.3s ease-in-out;
    color: var(--color-slate-400);
    font-size: 1.25rem;
  }
}

[data-part="item"]:hover {
  color: var(--color-slate-800);
  background-color: var(--color-slate-100);
  i {
    transition: color 0.3s ease-in-out;
    color: var(--color-slate-600);
  }
}
</style>
