<template>
  <footer>
    <div class="app-wrapper">
      <small>
        © MAPPREXPLORE S.L. Made with ❤️ in Barcelona
      </small>
      <small>
        <a href="mailto:info@mappr.com">
          info@mappr.com
        </a>
      </small>
    </div>
  </footer>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/breakpoints.pcss";
@import "@/assets/css/typography.pcss";

footer {
  background-color: var(--color-slate-100);
  > div {
    display: block;
    max-width: var(--default-content-width);
    margin: 0 auto;
    padding: 1rem 1rem;
    color: var(--color-slate-500);
    @mixin typeface-regular;
    @mixin screen sm {
      display: flex;
      padding: 1.08rem 1rem;
      justify-content: space-between;
    }
    a {
      color: var(--color-slate-500);
    }
  }
}
.subscribe {
  background-color: var(--color-slate-50);
  padding: 3rem 0;
  > div {
    text-align: center;
    max-width: 30rem;
    margin: 0 auto;
  }
}
</style>
