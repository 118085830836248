interface ToastMessage {
  toasts: Ref<Array<T>[]>;
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
  showWarning: (message: string) => void;
  showInfo: (message: string) => void;
}

export const useToast = ():ToastMessage => {
  const { t } = useI18n()
  const toasts = useState<Array[]>('toasts', () => [])

  const showToast = (message: string, options = {}) => {
    const toast = {
      id: Date.now(),
      message,
      duration: options.duration || 5000,
      type: options.type || 'info'
    }
    toasts.value.push(toast);
    setTimeout(() => {
      toasts.value = toasts.value.filter(t => t.id !== toast.id);
    }, toast.duration);
  }

  const showError = (message: string) => {
    showToast(message || t('messages.error'), {
      type: 'error'
    })
  }
  const showSuccess = (message: string) => {
    showToast(message || t('messages.success'), {
      type: 'success'
    })
  }
  const showWarning = (message: string) => {
    showToast(message || t('messages.warning'), {
      type: 'warning'
    })
  }
  const showInfo = (message: string) => {
    showToast(message || t('messages.info'), {
      type: 'info'
    })
  }

  return {
    toasts,
    showError,
    showSuccess,
    showWarning,
    showInfo
  }
}
