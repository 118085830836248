import { loadStripe } from '@stripe/stripe-js/pure'

const initStripe = () => {
  const config = useRuntimeConfig()
  if (import.meta.env.DEV) {
    loadStripe.setLoadParameters({
      advancedFraudSignals: false
    })
  }
  return loadStripe(config.public.stripePublicKey)
}

export default defineNuxtPlugin({
  name: 'stripe',
  parallel: true,
  async setup (nuxtApp) {
    const stripe = initStripe()
    nuxtApp.provide('stripe', stripe)
  }
})
