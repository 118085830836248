<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps({
  error: {
    type: Object as () => NuxtError,
    required: true
  }
})

const handleError = () => {
  clearError()
  const router = useRouter()
  router.back()
}
</script>

<template>
  <div>
    <MPHeader class="app-header" />
    <div class="app-content">
      <div class="app-wrapper error-page">
        <span>{{ error.statusCode }} ERROR</span>
        <h1>Oops... {{ error.statusMessage }}</h1>
        <p v-if="error.statusCode === 404">
          The page you requested could not be found.
        </p>
        <p v-else>
          We appologize there was an error with the application. If the error persists please contact support.
        </p>
        <div class="help-tips">
          <div>
            <strong><i class="ri-link" />Check the URL</strong>
            <p>Make sure the URL is correct. A small typo could have been the problem.</p>
          </div>
          <div>
            <strong><i class="ri-questionnaire-line" />Contact Support</strong>
            <p>If this is a persistant issue thats effecting you please <a href="mailto:info@mappr.com">contact support</a></p>
          </div>
          <div>
            <strong><i class="ri-arrow-left-circle-line" />Go back</strong>
            <p><a href="#" @click="handleError">Navigate back to the previous page</a> to pick up where you left off.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/breakpoints.scss";
.app-content {
  display: grid;
  align-content: space-evenly;
}
.error-page {
  margin: 0 auto;
  text-align: center;
  /* max-width: 320px; */
  height: fit-content;
  width: 100%;
  padding: 3rem;
  p {
    font-size: 1.1rem;
    color: var(--color-slate-500);
    max-width: 28rem;
    line-height: 1.5;
    margin: 0 auto;
  }
  span {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--color-indigo-400)
  }
  .help-tips {
    display: grid;
    gap: 1rem;
    margin: 6rem auto 0;
    max-width: 60rem;
    text-align: left;
    @include screen-md {
      grid-template-columns: repeat(3, 1fr);
    }
    a,
    a:visited {
      color: var(--color-indigo-400);
      cursor: pointer;
    }
    i {
      color: var(--color-indigo-400);
      margin-right: 0.5rem;
      font-size: 110%;
    }
    p {
      margin: 0;
    }
    > div {
      background-color: var(--color-slate-50);
      color: var(--color-slate-700);
      padding: 1rem 1.5rem 1.5rem;
      border-radius: 1rem;
      strong {
        font-size: 1.1rem;
        line-height: 2.5;
      }
    }
  }
}
</style>
