const isOpen = ref(false)
const dialogRef = ref<InstanceType<typeof MPDialog> | null>(null)

export const useDialog = () => {
  const { fetchCurrentUser } = useAuth()

  const setDialogRef = (ref: Ref<InstanceType<typeof MPDialog> | null>) => {
    dialogRef.value = ref.value
  }

  const openDialog = () => {
    if (dialogRef.value) {
      dialogRef.value.showDialog()
      isOpen.value = true
    }
  }

  const closeDialog = () => {
    if (dialogRef.value) {
      dialogRef.value.closeDialog()
      isOpen.value = false
    }
  }

  const onSuccess = async () => {
    await fetchCurrentUser()
    closeDialog()
  }

  return {
    isOpen,
    openDialog,
    closeDialog,
    onSuccess,
    setDialogRef
  }
}
