import * as Sentry from '@sentry/vue'
import { SupabaseClient } from '@supabase/supabase-js'
import { supabaseIntegration } from '@supabase/sentry-js-integration'



// async function lazyLoadSentryIntegrations () {
//   // don't load on server
//   if (!process.client) return

//   if (import.meta.env.DEV) return

//   const { Replay } = await import('@sentry/vue')
//   Sentry.addIntegration(new Replay({
//     maskAllText: false,
//     blockAllMedia: false
//   }))
// }

// function getSentryIntegrations () {
//   // don't load on server
//   if (!process.client) return []

//   const router = useRouter()
//   const browserTracing = new Sentry.BrowserTracing({
//     routingInstrumentation: Sentry.vueRouterInstrumentation(router)
//   })

//   return [browserTracing]
// }

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup (nuxtApp) {
    const vueApp = nuxtApp.vueApp
    const config = useRuntimeConfig()
    const router = useRouter()
    if (import.meta.env.DEV) return
    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC ?? null,
      trackComponents: true,
      // debug: true,
      integrations: [
        supabaseIntegration(SupabaseClient, Sentry, {
          tracing: true,
          breadcrumbs: true,
          errors: true,
        }),
        Sentry.browserProfilingIntegration(),
        Sentry.captureConsoleIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.browserTracingIntegration({
          router,
          shouldCreateSpanForRequest: (url) => {
            return !url.startsWith(`${process.env.SUPABASE_URL}/rest`)
          },
        }),
        // Sentry.feedbackIntegration({}),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false
        })
      ],
      tracePropagationTargets: ['localhost', /^https:\/\/mappr\.com/, 'https://api.mappr.com'],
      profilesSampleRate: 1.0,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
      // tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,
      // replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE as number,
      // replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number
    })
    nuxtApp.provide('sentry', Sentry)

    return {
      provide: {
        sentryInstance: Sentry
      }
    }
  }
})
